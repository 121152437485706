<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>{{ enquiry.name }}</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-simple-table class="mb-5 table" divider="true">
            <tbody>
              <tr>
                <th>Email</th>
                <td>
                  {{ enquiry.email }}
                </td>
              </tr>
              <tr>
                <th>Company</th>
                <td>
                  {{ enquiry.company }}
                </td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>
                  {{ enquiry.phone }}
                </td>
              </tr>
              <tr>
                <th>Comments</th>
                <td>
                  {{ enquiry.comments }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Image</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-img :src="enquiry.asset_urls.image" width="200"> </v-img>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Details</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.enquiryForm.openForm(enquiry)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>How Many Blinds</th>
                  <td>
                    {{ enquiry.how_many_blinds }}
                  </td>
                </tr>
                <tr>
                  <th>How Many Sides</th>
                  <td>
                    {{ enquiry.how_many_sides }}
                  </td>
                </tr>
                <tr>
                  <th>Orientation</th>
                  <td>
                    {{ enquiry.orientation }}
                  </td>
                </tr>
                <tr>
                  <th>Motorisation</th>
                  <td>
                    {{ enquiry.motorisation }}
                  </td>
                </tr>

                <tr>
                  <th>Fabric</th>
                  <td>
                    {{ enquiry.fabric }}
                  </td>
                </tr>

                <tr>
                  <th>Hex/Pantone/RAL Colour</th>
                  <td>
                    {{ enquiry.background }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Windows</div>
            </v-card-title>
            <v-data-table
              :headers="tableHeaders"
              :items="enquiry.windows"
              class="mt-4"
              :items-per-page="-1"
              no-data-text="No Windows found"
            >
              <template v-slot:item.photo="{ item }">
                <v-img :src="item.asset_urls.photo" width="100"> </v-img>
              </template>

              <template v-slot:item.positions="{ item }">
                y1: {{ item.position_y_1 }}<br />
                x1: {{ item.position_x_1 }}<br />
                y2: {{ item.position_y_2 }}<br />
                x2: {{ item.position_x_2 }}<br />
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <EnquiryForm ref="enquiryForm" />
  </div>
</template>

<script>
import EnquiryForm from "./components/EnquiryForm";
export default {
  components: { EnquiryForm },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Logo Printed Blinds",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Enquiries",
          disabled: false,
          exact: true,
          to: {
            name: "module-lpb-enquiries",
            params: { id: this.$route.params.id },
          },
        },
      ],
      tableHeaders: [
        { text: "Width", value: "width" },
        { text: "Height", value: "height" },
        { text: "Photo", value: "photo" },
        { text: "Positions", value: "positions" },
      ],

      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
      deleteEnquiry: {
        dialog: false,
        item: null,
        model: {},
        loading: false,
      },
    };
  },

  computed: {
    enquiry() {
      let enquiry = this.$store.getters["lpb/enquiries/get"];

      return enquiry;
    },
  },
};
</script>
